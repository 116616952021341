import React, { useState } from 'react';
import { css } from 'emotion';
import headerBB from './assets/BBLogoBlue.png';
import headerBBSmall from './assets/BBLogoSmall.png';
import insyncLogo from './assets/Insync-Logo-New.png';

const headerWrapper = css`
  display: flex;
  flex-direction: column;
`;

const colorHeader = css`
  background: #474747;
  width: 100%;
  padding: 5px 0px;
  font-family: Lato, 'sans-serif';
  line-height: 1.625em;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  letter-spacing: 2px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const phoneStyle = css`
  font-size: 14pt;
  letter-spacing: 0px;
  text-decoration: none;
  color: #ffffff;
`;

const linkStyle = css`
  color: #ffffff;
  text-decoration: none;
  padding-right: 1.25rem;
  font-size: 0.625rem;
  font-family: Lato, sans-serif;
  letter-spacing: 2px;
`;

const colorHeaderTabContainerStyle = css`
  display: flex;
  justify-content: space-between;
  min-width: 60%;
  align-items: center;
  height: 1.875rem;
`;

const mainHeaderWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ff6a00;
  @media (max-width: 1024px) {
    border-bottom: none;
  }
`;

const mainHeaderStyle = css`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 2rem;
  height: 5rem;
  @media (max-width: 1024px) {
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
  }
`;
const logoHeight = css`
  width: 123px;
`;

const mainHeaderTabContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  min-width: fit-content;
  height: 100%;
  left: 50%;
  top: 50%;
  width: 100%;
  padding-right: 2rem;
  transform: translate(-50%, -50%);
  @media (max-width: 1024px) {
    display: none;
  }
`;

const tabStyle = css`
  color: #ff7300;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.5em;
  text-transform: uppercase;
  text-decoration: none;
  min-width: fit-content;
  letter-spacing: 2px;
  padding: 0 15px;
  &:hover {
    color: #ff7300;
  }
`;

const logoStyle = css`
  padding: 0 15px;
  width: 9.56rem;
  height: 3.25rem;
`;
const imagePadding = css`
  padding-left: 1rem;
  vertical-align: bottom;
  height: 1.9rem;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const imagePaddingSmall = css`
  padding-left: 1rem;
  vertical-align: bottom;
  height: 2.4rem;
  @media (min-width: 1201px) {
    display: none;
  }
`;

const sideBarIcon = css`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    padding: 0 15px;
  }
`;

const sideBarStyle = css`
  height: 100%;
  padding: 20px;
`;

const sideBarTabStyle = css`
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #2d2e33;
  cursor: pointer;
  display: block;
  margin-bottom: 1rem;
  text-decoration: none;
  letter-spacing: 2px;
  &:hover {
    color: #ff7300;
  }
`;

const showSideBarOverlay = css`
  z-index: 9;
  height: 100%;
  width: 100%;
  background: #cac9c9;
  opacity: 0.6;
  position: fixed;
  top: 0;
  left: 0;
`;

const closeIconContainer = css`
  padding: 20px;
  stroke: #2d2e33;
  cursor: pointer;
  &:hover {
    stroke: #ff7300;
  }
`;

const sideBarWrapper = css`
  height: 100%;
  display: flex;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  padding-top: 16px;
  align-items: flex-start;
  transform: translateX(-101%);
  transition: transform 250ms;
`;

const hideSideBarOverlay = css`
  display: none;
`;

export const Header = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const showSideBarStyle = showSideBar ? { transform: 'translateX(0%)' } : {};

  const getTabs = tabStyleName => (
    <>
      <a className={tabStyleName} href="https://insyncinsurance.co.uk/beauty-insurance">
        Beauty
      </a>
      <a className={tabStyleName} href="https://insyncinsurance.co.uk/aesthetic-insurance">
        Aesthetics
      </a>
      <a className={tabStyleName} href="https://insyncinsurance.co.uk/medical-insurance">
        Medical
      </a>
      <a className={tabStyleName} href="https://insyncinsurance.co.uk">
        Commercial
      </a>
      <a className={tabStyleName} href="https://insyncinsurance.co.uk/get-a-quote">
        Get a quote
      </a>
      <a className={tabStyleName} href="https://blog.insyncinsurance.co.uk/">
        Blog
      </a>
    </>
  );

  return (
    <div className={headerWrapper}>
      <div>
        <div className={showSideBar ? showSideBarOverlay : hideSideBarOverlay} onClick={() => setShowSideBar(false)} />
        <div className={sideBarWrapper} style={showSideBarStyle}>
          <div className={sideBarStyle} onClick={e => e.stopPropagation()}>
            {getTabs(sideBarTabStyle)}
          </div>
          <div className={closeIconContainer} onClick={() => setShowSideBar(false)}>
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon">
              <line fill="none" strokeWidth="1.1" x1="1" y1="1" x2="13" y2="13" />
              <line fill="none" strokeWidth="1.1" x1="13" y1="1" x2="1" y2="13" />
            </svg>
          </div>
        </div>
      </div>
      <div className={colorHeader}>
        <div className={colorHeaderTabContainerStyle}>
          <a className={linkStyle} href="https://insyncinsurance.co.uk/insync-benefits">
            Benefits
          </a>
          <a className={linkStyle} href="https://insyncinsurance.co.uk/partnership">
            Partner
          </a>
          <a className={linkStyle} href="https://insyncinsurance.co.uk/about-us">
            About
          </a>
          <a
            className={linkStyle}
            href="https://www.feefo.com/en-GB/reviews/insync-insurance-solutions-ltd?displayFeedbackType=BOTH&timeFrame=YEAR"
          >
            Reviews
          </a>
          <a className={linkStyle} href="https://insyncinsurance.co.uk/our-team">
            Our team
          </a>
          <a className={linkStyle} href="https://insyncinsurance.co.uk/insurance-partners">
            Insurers
          </a>
          <a className={linkStyle} href="https://insyncinsurance.co.uk/call-back">
            Call back
          </a>
          <a className={linkStyle} href="https://blog.insyncinsurance.co.uk/tag/press-releases/">
            Press releases
          </a>
          <a className={linkStyle} href="https://insyncinsurance.co.uk/customer-support">
            Support
          </a>
          <p className={phoneStyle}>CALL US: 01200 309 516</p>
        </div>
      </div>
      <div className={mainHeaderWrapper}>
        <div className={mainHeaderStyle}>
          <a href="https://insyncinsurance.co.uk/" className={logoHeight}>
            <img src={insyncLogo} alt="logo" className={logoStyle} />
          </a>
          <div className={mainHeaderTabContainerStyle}>
            {getTabs(tabStyle)}
            <a target="_blank" rel="noopener noreferrer" href="https://www.bbinsurance.com/about-us/">
              <img src={headerBB} alt="feefo" className={imagePadding} />
              <img src={headerBBSmall} alt="feefo" className={imagePaddingSmall} />
            </a>
          </div>
          <div className={sideBarIcon} onClick={() => setShowSideBar(true)}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              data-svg="navbar-toggle-icon"
            >
              <rect y="9" width="20" height="2" />
              <rect y="3" width="20" height="2" />
              <rect y="15" width="20" height="2" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
