import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Loader } from './components/components/Loader';
import { InnerGrid } from './components/components/InnerGrid';
import ReferralScreen from './components/Referral/ReferralScreen';
import { Maintenance } from './components/Maintenance/Maintenance';

export const lazyWithRetries = importer => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error) {
      // retry 5 times with 2 second delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds)
      for (let i = 0; i < 5; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000 * 2 ** i));
        // this assumes that the exception will contain this specific text with the url of the module
        // if not, the url will not be able to parse and we'll get an error on that
        // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"
        const url = new URL(error.message.replace('Failed to fetch dynamically imported module: ', '').trim());
        // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
        url.searchParams.set('t', `${+new Date()}`);

        try {
          return await import(url.href);
        } catch (e) {
          console.log('retrying import');
        }
      }
      throw error;
    }
  };
  return React.lazy(retryImport);
};

const Main = lazyWithRetries(() => import('./components/Main/Main'));
const ErrorPage = lazyWithRetries(() => import('./components/Error/ErrorPage'));
const SuccessPage = lazyWithRetries(() => import('./components/Success/SuccessPage'));
const SavedQuote = lazyWithRetries(() => import('./components/SavedQuote/SavedQuote'));

export const App = () => {
  const history = useHistory();
  useEffect(
    () =>
      history.listen(() => {
        if (history.action === 'POP') {
          history.go(0);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );
  return (
    <Suspense
      fallback={
        <InnerGrid>
          <div />
          <Loader message="Please wait..." />
        </InnerGrid>
      }
    >
      <Switch>
        <Route exact path="/error">
          <ErrorPage />
        </Route>
        <Route exact path="/success">
          <SuccessPage />
        </Route>
        <Route exact path="/referral">
          <ReferralScreen />
        </Route>
        <Route exact path="/quoteSaved">
          <SavedQuote />
        </Route>
        <Route exact path="/maintenance">
          <Maintenance />
        </Route>
        <Route path="/">
          <Main />
        </Route>
        <Redirect from="*" to="/" />
      </Switch>
    </Suspense>
  );
};
